@font-face {
  font-family: 'Venice Classic';
  src: url('../public/assets/fonts/VeniceClassic.woff2') format('woff2');
}

@font-face {
  font-family: 'Raleway';
  src: url('../public/assets/fonts/Raleway.woff2') format('woff2');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes chest-wiggle {
  from {
    transform: rotate(0deg);
  }
  
  3.125% {
    transform: rotate(-10deg);
  }
  9.375% {
    transform: rotate(10deg);
  }
  12.5% {
    transform: rotate(0deg);
  }
  15.625% {
    transform: rotate(-10deg);
  }
  21.875% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.animate-chest-wiggle {
  animation: chest-wiggle 3s linear infinite;
  animation-delay: 1000ms;
}